import React, { useState } from "react";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Headline from "../../../components/Comon/Headline/Headline";
import Button from "../../../components/Comon/Button/Button";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";
import {practiceHubSetApiKey, practiceHubSyncUsers, practiceHubUpdateSettings} from "../../../../core/axios/services";
import {useDispatch, useSelector} from "react-redux";
import {addUser} from "../../../../redux/actions/user";
import { Oval } from "react-loader-spinner";

export const Integrations = ({ app, i18n, showGlobalSnackbar }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const [apiKeyFormData, setApiKeyFormData] = useState({
    apiKey: user?.user?.clinic?.practiceHubSettings?.apiKey ?? '',
    accountDomain: user?.user?.clinic?.practiceHubSettings?.accountDomain ?? '',
    accountRegion: user?.user?.clinic?.practiceHubSettings?.accountRegion ?? '',
  });
  const [apiKeyErrorData, setApiKeyErrorData] = useState();
  const [apiKeyStatus, setApiKeyStatus] = useState(false)
  
  async function handleSetApiKey() {
    setApiKeyStatus(true);
    let response = await practiceHubSetApiKey(apiKeyFormData, user);
    if (response?.status === 200) {
      dispatch(addUser(response.data.data));
      setApiKeyStatus(false);
      showGlobalSnackbar(i18n.t("saved"));
    } else {
      setApiKeyErrorData(response?.response?.data?.messages);
    }
  }

  let sendingOptions = [
    {id: 1, name: "before", value: "before",},
    {id: 2, name: "after", value: "after",},
  ];
  let numOfDaysOptions = [
    {id: 1, name: "1 Day", value: 1},
    {id: 2, name: "2 Days", value: 2},
    {id: 3, name: "3 Days", value: 3},
    {id: 4, name: "4 Days", value: 4},
    {id: 5, name: "5 Days", value: 5},
    {id: 6, name: "6 Days", value: 6},
    {id: 7, name: "7 Days", value: 7},
    {id: 15, name: "15 Days", value: 15},
    {id: 30, name: "Month", value: 30},
  ];
  const [sendingInfoFormData, setSendingInfoFormData] = useState({
    emailBody: user?.user?.clinic?.practiceHubSettings?.emailBody ?? null,
    defaultSurveyType: user?.user?.clinic?.practiceHubSettings?.defaultSurveyType ?? null,
    defaultSurveyLanguage: user?.user?.clinic?.practiceHubSettings?.defaultSurveyLanguage ?? null,
    appointmentTypeTrigger: user?.user?.clinic?.practiceHubSettings?.appointmentTypeTrigger ?? null,
    sendingOption: user?.user?.clinic?.practiceHubSettings?.sendingOption ? sendingOptions.find(obj => obj.value === user?.user?.clinic?.practiceHubSettings?.sendingOption) : null,
    numOfDaysSending: user?.user?.clinic?.practiceHubSettings?.numOfDaysSending ? numOfDaysOptions.find(obj => obj.value === user?.user?.clinic?.practiceHubSettings?.numOfDaysSending) : null,
  });
  const [sendingInfoErrorData, setSendingInfoErrorData] = useState();
  const [sendingInfoStatus, setSendingInfoStatus] = useState(false)
  async function handleSendingInfo() {
    setSendingInfoStatus(true);
    let response = await practiceHubUpdateSettings({
      ...sendingInfoFormData,
      sendingOption: sendingInfoFormData?.sendingOption?.value,
      numOfDaysSending: sendingInfoFormData?.numOfDaysSending?.value,
    }, user)
    if (response?.status === 200) {
      dispatch(addUser(response.data.data));
      showGlobalSnackbar(i18n.t("saveAndSyncMessage"));
    } else {
      setSendingInfoErrorData(response?.response?.data?.messages);
    }
    setSendingInfoStatus(false);
  }


  const [syncUsersErrorData, setSyncUsersErrorData] = useState();
  const [syncUsersStatus, setSyncUsersStatus] = useState(false)
  async function handleSyncUsers() {
    setSyncUsersStatus(true);
    let response = await practiceHubSyncUsers(user);
    if (response?.status === 200) {
      showGlobalSnackbar(i18n.t("syncSuccessMessage"));
    } else {
      setSyncUsersErrorData(response?.response?.data?.messages);
    }
    setSyncUsersStatus(false);
  }

  

  return (
    <div className="integrations">
      <Box className={"mainProfile"}>
        <Headline text={i18n.t("practiceHub")} type={"Headline-medium"} />

        <Box className={"sectionChange"}>
          <Box className={"textInputContainer"}>
            <TextInput
              type={"password"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, apiKey: value })
              }
              label={i18n.t("APIKey")}
              value={apiKeyFormData?.apiKey}
              mode={"grey"}
              explanation={i18n.t("keyDesc")}
              errorMessage={apiKeyErrorData?.apiKey}
            />
            <TextInput
              type={"text"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, accountDomain: value })
              }
              label={i18n.t("accountDomain")}
              value={apiKeyFormData?.accountDomain}
              mode={"grey"}
              explanation={i18n.t("accDesc")}
              errorMessage={apiKeyErrorData?.accountDomain}
            />
            <TextInput
              type={"text"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, accountRegion: value })
              }
              label={i18n.t("acccountRegion")}
              value={apiKeyFormData?.accountRegion}
              mode={"grey"}
              explanation={i18n.t("accRegionDesc")}
              errorMessage={apiKeyErrorData?.accountRegion}
            />
            <Box
              style={{ marginTop: "0px" }}
              className={"buttonContainer"}
              onClick={() => handleSetApiKey()}
            >
              <Button
                text={
                  apiKeyStatus ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    i18n.t("confirmSurveyNotes")
                  )
                }
                mode={"small"}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {user?.user?.clinic?.hasPracticeHubIntegrated && (
        <>
          <Box className={"mainProfile"}>
            <Headline
              text={i18n.t("automateProcess")}
              type={"Headline-medium"}
            />

            <Box className={"sectionChange integrationDropdownWrapper"}>
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("defaultSurveyType")}
                </span>
                <Dropdown
                    type={"statistics"}
                    setSelectedOption={(option) =>
                        setSendingInfoFormData({
                          ...sendingInfoFormData,
                          defaultSurveyType: option,
                        })
                    }
                    selectedOption={sendingInfoFormData?.defaultSurveyType}
                    options={[
                      {id: 1, name: "ChiroForm"},
                      {id: 2, name: "ChiroForm +"},
                      {id: 3, name: "ChiroForm (CP)"},
                    ]}
                    app={app}
                    placeholder="Select"
                />
                {sendingInfoErrorData?.["defaultSurveyType.id"] &&
                    sendingInfoErrorData?.["defaultSurveyType.id"]?.map(
                        (item, index) => {
                          return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                          );
                        }
                    )}
              </div>
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("defaultSurveyLanguage")}
                </span>
                <Dropdown
                    type={"statistics"}
                    setSelectedOption={(option) =>
                        setSendingInfoFormData({
                          ...sendingInfoFormData,
                          defaultSurveyLanguage: option,
                        })
                    }
                    selectedOption={sendingInfoFormData?.defaultSurveyLanguage}
                    options={user?.user?.clinic?.languages}
                    app={app}
                    placeholder="Select"
                />
                {sendingInfoErrorData?.["defaultSurveyLanguage.id"] &&
                    sendingInfoErrorData?.["defaultSurveyLanguage.id"]?.map(
                        (item, index) => {
                          return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                          );
                        }
                    )}
              </div>
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("appointmentTypeTrigger")}
                </span>
                <Dropdown
                    type={"statistics"}
                    setSelectedOption={(option) =>
                        setSendingInfoFormData({
                          ...sendingInfoFormData,
                          appointmentTypeTrigger: option,
                        })
                    }
                    selectedOption={sendingInfoFormData?.appointmentTypeTrigger}
                    options={
                      user?.user?.clinic?.practiceHubSettings?.appointmentTypes
                    }
                    app={app}
                    placeholder="Select"
                />
                {sendingInfoErrorData?.["appointmentTypeTrigger.id"] &&
                    sendingInfoErrorData?.["appointmentTypeTrigger.id"]?.map(
                        (item, index) => {
                          return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                          );
                        }
                    )}
              </div>
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("sendSurveyAppointment")}
                </span>
                <div className="dropdownGridWrap">
                  <div>
                    <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                            setSendingInfoFormData({
                              ...sendingInfoFormData,
                              sendingOption: option,
                            })
                        }
                        selectedOption={sendingInfoFormData?.sendingOption}
                        options={sendingOptions}
                        app={app}
                        placeholder="Select"
                    />
                    {sendingInfoErrorData?.["sendingOption"] &&
                        sendingInfoErrorData?.["sendingOption"]?.map(
                            (item, index) => {
                              return (
                                  <div key={index} className={"errorMessage"}>
                                    {item}
                                  </div>
                              );
                            }
                        )}
                  </div>

                  <div>
                    <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                            setSendingInfoFormData({
                              ...sendingInfoFormData,
                              numOfDaysSending: option,
                            })
                        }
                        selectedOption={sendingInfoFormData?.numOfDaysSending}
                        options={numOfDaysOptions}
                        app={app}
                        placeholder="Select"
                    />
                    {sendingInfoErrorData?.["numOfDaysSending"] &&
                        sendingInfoErrorData?.["numOfDaysSending"]?.map(
                            (item, index) => {
                              return (
                                  <div key={index} className={"errorMessage"}>
                                    {item}
                                  </div>
                              );
                            }
                        )}
                  </div>
                </div>
              </div>


              <div className={"integrationDropdown"}>
                <TextInput
                    maxlength={500}
                    type={"textarea"}
                    setValue={(value) =>
                        setSendingInfoFormData({...sendingInfoFormData, emailBody: value})
                    }
                    label={i18n.t("emailBody")}
                    value={sendingInfoFormData?.emailBody}
                    mode={"grey"}
                    errorMessage={sendingInfoErrorData?.emailBody}
                />
              </div>
            </Box>

            <Box
                style={{marginTop: "24px"}}
                className={"buttonContainer"}
                onClick={() => handleSendingInfo()}
            >
              <Button
                  text={
                    sendingInfoStatus ? (
                        <Oval
                            height={20}
                            width={20}
                            color="#fff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    i18n.t("saveSync")
                  )
                }
                mode={"small"}
              />
            </Box>
          </Box>

          {user?.user?.clinic?.practiceHubSettings?.canFetchUsers && (
            <Box className={"mainProfile"}>
              <Headline
                text={i18n.t("manualSyncOptions")}
                type={"Headline-medium"}
              />
              <p style={{ marginTop: "42px", maxWidth: "580px" }}>
                {i18n.t("manualDesc")}
              </p>
              <Box
                style={{ marginTop: "10px", marginBottom: "40px" }}
                className={"buttonContainer"}
                onClick={() => handleSyncUsers()}
              >
                <Button
                  text={
                    syncUsersStatus ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      i18n.t("syncNow")
                    )
                  }
                  mode={"small"}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
